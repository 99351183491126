<template>
  <div class="pidReport">
    <div class="title">
      <h2>Pid Report</h2>
    </div>
    <div class="bgf0">
      <el-row type="flex">
        <el-col :span="24" style="padding:16px;">
          <el-card shadow="hover" class="pad30">
            <el-form ref="tableForm" :model="pidReport" :inline="true">
              <el-row :gutter="24">
                <el-col :xs="24" :sm="2" :md="2" :lg="2" :xl="2">
                  <label class="labelName">Pid</label>
                </el-col>
                <el-form-item
                  label="pid"
                  label-width="1.8rem"
                  :rules="[{ required: true, message: 'pid is required' }]"
                  prop="pidParam"
                >
                  <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
                    <el-input
                      v-model="pidReport.pidParam"
                      placeholder="format: pid=xxx or xxx"
                    ></el-input>
                  </el-col>
                </el-form-item>
              </el-row>
              <el-row :gutter="24">
                <el-col :xs="24" :sm="2" :md="2" :lg="2" :xl="2">
                  <label class="labelName">AfPrt</label>
                </el-col>
                <el-form-item label="AfPrt" label-width="1.8rem" prop="afPrtList">
                  <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
                    <el-input v-model="pidReport.afPrtList" placeholder="afPrtList here"></el-input>
                  </el-col>
                </el-form-item>
              </el-row>
              <el-row :gutter="24">
                <el-col :xs="24" :sm="2" :md="2" :lg="2" :xl="2">
                  <label class="labelName">Offer(s)</label>
                </el-col>
                <el-form-item label="OfferId(s)" label-width="1.8rem">
                  <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
                    <el-input
                      v-model="pidReport.offerIds"
                      placeholder="offerIds: eg. 2206264 or 2206264,1567396"
                    ></el-input>
                  </el-col>
                </el-form-item>
                <el-form-item>
                  <el-col :xs="1" :sm="1" :md="1" :lg="1" :xl="1">
                    <el-checkbox v-model="offerChecked"></el-checkbox>
                  </el-col>
                </el-form-item>
              </el-row>
              <el-row :gutter="24">
                <el-col :xs="24" :sm="2" :md="2" :lg="2" :xl="2">
                  <label class="labelName">Affiliate(s)</label>
                </el-col>
                <el-form-item label="AffiliateId(s)" label-width="2.08rem">
                  <el-col :xs="24" :sm="20" :md="20" :lg="20" :xl="20">
                    <group-select
                      v-model="pidReport.affiliateIds"
                      :data-source="affiliatesGroupList"
                      multiple
                      :loading="affiliateLoading"
                      clearable
                      reserve-keyword
                      filterable
                      placeholder="请选择"
                      style="width:10.14rem;"
                    />
                  </el-col>
                </el-form-item>
                <el-form-item>
                  <el-col :xs="1" :sm="1" :md="1" :lg="1" :xl="1">
                    <el-checkbox v-model="affiliateChecked"></el-checkbox>
                  </el-col>
                </el-form-item>
              </el-row>
              <el-row :gutter="24">
                <el-col :xs="24" :sm="2" :md="2" :lg="2" :xl="2">
                  <label class="labelName">Source(s)</label>
                </el-col>
                <el-form-item label="Source(s)" label-width="1.8rem">
                  <el-col :xs="24" :sm="20" :md="20" :lg="20" :xl="20">
                    <group-select
                      v-model="pidReport.sourceIds"
                      :data-source="sourcesList"
                      multiple
                      :loading="sourceLoading"
                      reserve-keyword
                      clearable
                      filterable
                      placeholder="请选择"
                      style="width:10.4rem;"
                    />
                  </el-col>
                </el-form-item>
                <el-form-item>
                  <el-col :xs="1" :sm="1" :md="1" :lg="1" :xl="1">
                    <el-checkbox v-model="sourceChecked"></el-checkbox>
                  </el-col>
                </el-form-item>
              </el-row>
              <el-row>
                <label class="labelName" style="margin-right:20px;">TimeZone</label>
                <el-form-item>
                  <el-select v-model="pidReport.timezone" style="width:420px;">
                    <el-option
                      v-for="item in timezoneOption"
                      :key="item.value"
                      :value="item.value"
                      :label="item.label"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-row>
              <el-row>
                <el-col :lg="3" :md="3" :xs="3" :sm="3" :xl="3">
                  <label class="labelName">Interval</label>
                </el-col>
                <el-col :lg="8" :md="8" :xs="8" :sm="8" :xl="8">
                  <el-radio-group v-model="pidReport.dateOrMonth">
                    <el-radio label="yyyy-MM-dd">Date</el-radio>
                    <el-radio label="yyyy-MM">Month</el-radio>
                    <el-radio label="yyyy-MM-dd HH:mm:ss">Hour</el-radio>
                  </el-radio-group>
                </el-col>
              </el-row>
              <el-row>
                <label class="labelName" style="margin-right:60px;">Date</label>
                <el-form-item>
                  <el-date-picker
                    v-model="startToEndDate"
                    format="yyyy-MM-dd"
                    value-format="yyyy-MM-dd"
                    type="daterange"
                    style="width:260px;"
                    align="right"
                    unlink-panels
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                    :picker-options="pickerOptions"
                  ></el-date-picker>
                </el-form-item>
              </el-row>
              <el-row>
                <el-col :lg="3" :md="3" :xs="3" :sm="3" :xl="3">
                  <label class="labelName" style="margin-right:40px;">Sort by</label>
                </el-col>
                <el-col :lg="20" :md="20">
                  <el-form-item>
                    <el-radio-group v-model="pidReport.sortColumn">
                      <el-radio
                        style="margin-right:140px;"
                        v-for="item in radioOptions"
                        :key="item"
                        :label="item"
                      >
                        {{ item }}
                      </el-radio>
                    </el-radio-group>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <el-col :lg="3" :md="20" :offset="1">
                  <el-form-item>
                    <el-button type="primary" @click="searchListClick(1)">In Browser</el-button>
                  </el-form-item>
                </el-col>
                <el-col :lg="3" :md="20">
                  <el-form-item>
                    <el-button type="primary" @click="exportExcelFileClick"
                      >Download Excel</el-button
                    >
                  </el-form-item>
                </el-col>
              </el-row>
            </el-form>
          </el-card>
        </el-col>
      </el-row>
      <el-card shadow="hover">
        <el-table
          v-loading="listLoading"
          ref="tableRef"
          style="width:100%;"
          height="70vmin"
          stripe
          border
          :data="pidReportList"
          highlight-current-row
          @selection-change="handleSelectionChange"
        >
          <el-table-column type="selection" width="55" v-if="offerChecked"> </el-table-column>
          <el-table-column
            label="Date"
            width="100"
            prop="toChar"
            align="center"
            :fixed="fullWidth <= 768 ? false : 'left'"
            key="date"
          ></el-table-column>
          <el-table-column
            label="Affiliate ID"
            width="100"
            align="center"
            :fixed="fullWidth <= 768 ? false : 'left'"
            v-if="affiliateChecked"
            key="affiliateId"
          >
            <template slot-scope="scope">
              <span class="cor337ab7" @click="affiliatetailClick(scope.row)">{{
                scope.row.affiliateId
              }}</span>
            </template>
          </el-table-column>
          <el-table-column
            label="Source ID"
            width="100"
            key="sourceId"
            v-if="sourceChecked"
            align="center"
          >
            <template slot-scope="scope">
              <span class="cor337ab7" @click="sourcedetailClick(scope.row)">{{
                scope.row.sourceId
              }}</span>
            </template>
          </el-table-column>
          <el-table-column
            label="Source Name"
            width="110"
            prop="sourceName"
            v-if="sourceChecked"
            align="center"
            key="sourceName"
          >
            <template slot-scope="scope">
              <span class="cor337ab7" @click="sourcedetailClick(scope.row)">{{
                scope.row.sourceName
              }}</span>
            </template>
          </el-table-column>
          <el-table-column
            label="Offer ID"
            width="100"
            v-if="offerChecked"
            key="offerId"
            align="center"
          >
            <template slot-scope="scope">
              <span class="cor337ab7" @click="offerdetailClick(scope.row)">{{
                scope.row.offerId
              }}</span>
            </template>
          </el-table-column>
          <el-table-column label="Offer Name" v-if="offerChecked" min-width="100" align="center">
            <template slot-scope="scope">
              <span class="cor337ab7" @click="offerdetailClick(scope.row)">{{
                scope.row.offerName
              }}</span>
            </template>
          </el-table-column>
          <el-table-column
            label="AfPrt"
            prop="afPrt"
            width="150"
            align="center"
            key="afPrt"
          ></el-table-column>
          <el-table-column
            label="Prod"
            prop="prod"
            width="200"
            align="center"
            key="prod"
          ></el-table-column>
          <el-table-column
            label="Offer Status"
            prop="status"
            v-if="offerChecked"
            width="110"
            align="center"
          >
          </el-table-column>
          <el-table-column label="Total Clicks" width="100" key="totalClicks" show-overflow-tooltip>
            <template slot-scope="scope">
              <span>{{ scope.row.totalClicks }}</span>
            </template>
          </el-table-column>
          <el-table-column
            label="Clicks"
            width="100"
            align="center"
            key="clicks"
            show-overflow-tooltip
          >
            <template slot-scope="scope">
              <span>{{ scope.row.clicks }}</span>
            </template>
          </el-table-column>
          <el-table-column
            label="Conversions"
            width="120"
            align="center"
            prop="conversions"
            key="conversions"
            show-overflow-tooltip
          >
          </el-table-column>
          <el-table-column
            label="Revenue"
            width="100"
            prop="revenue"
            align="center"
            key="revenue"
          ></el-table-column>
          <el-table-column
            label="Aff Conversions"
            width="130"
            align="center"
            key="affConversion"
            show-overflow-tooltip
            prop="affConversionCount"
          >
          </el-table-column>
          <el-table-column
            label="Payout"
            width="100"
            prop="payout"
            align="center"
            key="payout"
          ></el-table-column>
          <el-table-column label="CR" width="100" align="center" key="cr" show-overflow-tooltip>
            <template slot-scope="scope">
              <span
                v-html="
                  showReportComparingCrItem(
                    filterNull(scope.row.conversions),
                    filterNull(scope.row.clicks)
                  )
                "
              ></span>
            </template>
          </el-table-column>
          <el-table-column label="RPC" width="100" align="center" key="rpc" show-overflow-tooltip>
            <template slot-scope="scope">
              <span
                v-html="
                  showReportComparingRpcItem(
                    filterNull(scope.row.affConversionCount),
                    filterNull(scope.row.clicks)
                  )
                "
              ></span>
            </template>
          </el-table-column>
          <el-table-column
            label="Platform"
            prop="platform"
            width="100"
            align="center"
            v-if="platformChecked"
            key="platform"
          ></el-table-column>
        </el-table>
        <pagination
          class="block pagePanel"
          :pageTotal="total"
          @handleSizeChange="handleSizeChange"
          @handleCurrentChange="handleCurrentChange"
          :pageSize="pageParam.pageSize"
          :currentPage="pageParam.page"
        ></pagination>
      </el-card>
    </div>
  </div>
</template>
<script>
  import { api, getPidReport } from 'api/report';

  import Pagination from '@/components/pagination';
  import GroupSelect from '@/components/GroupSelect';
  import { cloneDeep } from 'lodash-es';
  import dateUtils from '@/utils/dateutils.js';
  import qs from 'querystring';
  import { myMixin } from '@/mixins/mixins.js';
  import optionData from '@/assets/js/optionData';
  import { filterObject } from 'utils/utils';
  import { mapState, mapActions } from 'vuex';

  export default {
    mixins: [myMixin],
    components: {
      Pagination,
      GroupSelect,
    },
    data() {
      return {
        pageParam: {
          page: 1,
          pageSize: 20,
        },
        dataParam: null,
        offerChecked: true,
        affiliateChecked: false,
        sourceChecked: false,
        fullWidth: document.documentElement.clientWidth,
        platformChecked: false,
        startToEndDate: '',
        listLoading: false,
        total: 0,
        radioOptions: ['payout', 'clicks', 'conversions'],
        summariesMap: {},
        pidReportList: [],
        timezoneOption: optionData.timezoneOption,
        pidReport: {
          offerIds: null,
          status: null,
          affiliateIds: null,
          groupColumns: [],
          comparing: true,
          fromDate: null,
          toDate: null,
          sortColumn: 'payout',
          sourceIds: null,
          timezone: '+00:00',
          dateOrMonth: 'yyyy-MM-dd',
          afPrtList: '',
        },
        historyDeductList: [],
        sourceIdArray: [],
        affiliateIdArray: [],
        blackInfo: {
          blackList: [],
          description: '',
        },
      };
    },
    mounted() {
      //默认选择
      const end = new Date();
      const start = new Date();
      start.setTime(start.getTime() + 3600 * 1000 * 24);
      this.startToEndDate = [];
      this.startToEndDate[0] = dateUtils.date2StrYMD(end);
      this.startToEndDate[1] = dateUtils.date2StrYMD(start);

      this.getAffiliateList();
      this.getSourceIdsList();
    },
    computed: {
      ...mapState('affiliate', {
        affiliatesGroupList: (state) => state.affiliatesGroupList,
        affiliateLoading: (state) => state.affiliateLoading,
      }),
      ...mapState('source', {
        sourcesList: (state) => state.sourcesList,
        sourceLoading: (state) => state.sourceLoading,
      }),
      listenChange() {
        const {
          affiliateChecked,
          sourceChecked,
          affSubsChecked,
          offerChecked,
          platformChecked,
        } = this;
        return {
          affiliateChecked,
          sourceChecked,
          affSubsChecked,
          offerChecked,
          platformChecked,
        };
      },
    },
    watch: {
      listenChange: function() {
        this.$nextTick(() => {
          this.$refs.tableRef.doLayout();
        });
      },
    },
    methods: {
      ...mapActions('affiliate', ['getAffiliateList']),
      ...mapActions('source', ['getSourceIdsList']),
      getParams(curPage) {
        if (curPage) {
          this.pageParam.page = curPage;
        }
        let param = {
          ...this.pidReport,
          ...this.pageParam,
        };

        if (this.offerChecked == true && param.groupColumns.indexOf('offer_id') == -1) {
          param.groupColumns.push('offer_id');
        } else if (this.offerChecked == false && param.groupColumns.indexOf('offer_id') !== -1) {
          const offerIdIndex = param.groupColumns.findIndex((item) => {
            if (item == 'offer_id') {
              return true;
            }
          });
          param.groupColumns.splice(offerIdIndex, 1);
        }

        if (
          this.affiliateChecked == true &&
          param.groupColumns.indexOf('affiliate_id') == -1 &&
          param.groupColumns.indexOf('company') == -1
        ) {
          param.groupColumns.push('affiliate_id');
          param.groupColumns.push('company');
        } else if (
          this.affiliateChecked == false &&
          param.groupColumns.indexOf('affiliate_id') !== -1 &&
          param.groupColumns.indexOf('company') !== -1
        ) {
          const affiliateIndex = param.groupColumns.findIndex((item) => {
            if (item == 'affiliate_id') {
              return true;
            }
          });
          param.groupColumns.splice(affiliateIndex, 1);

          const companyIndex = param.groupColumns.findIndex((item) => {
            if (item == 'company') {
              return true;
            }
          });
          param.groupColumns.splice(companyIndex, 1);
        }

        if (
          this.sourceChecked == true &&
          param.groupColumns.indexOf('source_id') == -1 &&
          param.groupColumns.indexOf('source_name') == -1
        ) {
          param.groupColumns.push('source_id');
          param.groupColumns.push('source_name');
        } else if (
          this.sourceChecked == false &&
          param.groupColumns.indexOf('source_id') !== -1 &&
          param.groupColumns.indexOf('source_name') !== -1
        ) {
          const sourceIdIndex = param.groupColumns.findIndex((item) => {
            if (item == 'source_id') {
              return true;
            }
          });
          param.groupColumns.splice(sourceIdIndex, 1);

          const sourceNameIndex = param.groupColumns.findIndex((item) => {
            if (item == 'source_name') {
              return true;
            }
          });
          param.groupColumns.splice(sourceNameIndex, 1);
        }

        if (this.platformChecked == true && param.groupColumns.indexOf('platform') == -1) {
          param.groupColumns.push('platform');
        } else if (this.platformChecked == false && param.groupColumns.indexOf('platform') !== -1) {
          const platformIndex = param.groupColumns.findIndex((item) => {
            if (item == 'platform') {
              return true;
            }
          });
          param.groupColumns.splice(platformIndex, 1);
        }
        if (this.startToEndDate.length === 2) {
          let startDate = this.startToEndDate[0];
          let endDate = this.startToEndDate[1];
          param.fromDate = startDate;
          param.toDate = endDate;
        }
        if (this.pidReport.offerIds) {
          param.offerIds = this.pidReport.offerIds.split(',').map(parseInt);
        }
        if (this.pidReport.afPrtList) {
          param.afPrtList = this.pidReport.afPrtList.split(',');
        }

        const dataParam = cloneDeep(param);
        this.dataParam = dataParam;
      },
      searchListClick(curPage) {
        this.$refs.tableForm.validate((valid) => {
          if (!valid) {
            this.$message.warning('pid is required');
            return false;
          }
          this.getParams(curPage);
          this.pidReportApi();
        });
      },
      pidReportApi() {
        this.listLoading = true;
        let param = {
          ...this.dataParam,
          ...this.pageParam,
        };
        param = filterObject(param);
        getPidReport(param)
          .then((response) => {
            this.listLoading = false;
            if (response.code === 200) {
              const result = response.result || {};
              this.pidReportList = result?.data;
              this.summariesMap = result?.totalLine;
              this.total = result?.total;
              if (this.summariesMap) {
                const totalObject = new Object();
                totalObject.toChar = 'Total';
                totalObject.clicks = this.summariesMap.clicks;
                totalObject.conversions = this.summariesMap.conversions;
                totalObject.revenue = this.summariesMap.revenue;
                totalObject.totalClicks = this.summariesMap.totalClicks;
                totalObject.affConversionCount = this.summariesMap.affConversionCount;
                totalObject.payout = this.summariesMap.payout;
                totalObject.prod = this.summariesMap.prod;
                this.pidReportList.push(totalObject);
              }
            } else {
              this.pidReportList = [];
              this.summariesMap = {};
              this.total = 0;
              this.$message.error(response.message);
            }
          })
          .catch(() => {
            this.listLoading = false;
            this.pidReportList = [];
            this.summariesMap = {};
            this.total = 0;
          });
      },
      exportExcelFileClick() {
        this.$refs.tableForm.validate((valid) => {
          if (!valid) {
            this.$message.warning('pid is required');
            return false;
          }
          if (this.sourceIdArray != [] && this.sourceIdArray.length > 0) {
            this.pidReport.sourceIds = this.sourceIdArray;
          }

          if (this.affiliateIdArray != [] && this.affiliateIdArray.length > 0) {
            this.pidReport.affiliateIds = this.affiliateIdArray;
          }
          this.getParams(1);
          let param = {
            ...this.dataParam,
            ...this.pageParam,
          };
          let groupColumns = new Array();

          if (this.p == true && groupColumns.indexOf('offer_id') == -1) {
            groupColumns.push('offer_id');
          } else if (this.offerChecked == false && groupColumns.indexOf('offer_id') !== -1) {
            const offerIdIndex = groupColumns.findIndex((item) => {
              if (item == 'offer_id') {
                return true;
              }
            });
            groupColumns.splice(offerIdIndex, 1);
          }
          if (
            this.affiliateChecked == true &&
            groupColumns.indexOf('affiliate_id') == -1 &&
            groupColumns.indexOf('company') == -1
          ) {
            groupColumns.push('affiliate_id');
            groupColumns.push('company');
          } else if (
            this.affiliateChecked == false &&
            groupColumns.indexOf('affiliate_id') !== -1 &&
            groupColumns.indexOf('company') !== -1
          ) {
            const affiliateIndex = groupColumns.findIndex((item) => {
              if (item == 'affiliate_id') {
                return true;
              }
            });
            groupColumns.splice(affiliateIndex, 1);

            const companyIndex = groupColumns.findIndex((item) => {
              if (item == 'company') {
                return true;
              }
            });
            groupColumns.splice(companyIndex, 1);
          }

          if (
            this.sourceChecked == true &&
            groupColumns.indexOf('source_id') == -1 &&
            groupColumns.indexOf('source_name') == -1
          ) {
            groupColumns.push('source_id');
            groupColumns.push('source_name');
          } else if (
            this.sourceChecked == false &&
            groupColumns.indexOf('source_id') !== -1 &&
            groupColumns.indexOf('source_name') !== -1
          ) {
            const sourceIdIndex = groupColumns.findIndex((item) => {
              if (item == 'source_id') {
                return true;
              }
            });
            groupColumns.splice(sourceIdIndex, 1);

            const sourceNameIndex = groupColumns.findIndex((item) => {
              if (item == 'source_name') {
                return true;
              }
            });
            groupColumns.splice(sourceNameIndex, 1);
          }

          if (this.offerChecked == true && groupColumns.indexOf('offer_id') == -1) {
            groupColumns.push('offer_id');
          } else if (this.offerChecked == false && groupColumns.indexOf('offer_id') !== -1) {
            const offerIdIndex = groupColumns.findIndex((item) => {
              if (item == 'offer_id') {
                return true;
              }
            });
            groupColumns.splice(offerIdIndex, 1);
          }

          param['fromDate'] = this.startToEndDate[0];
          param['toDate'] = this.startToEndDate[1];
          if (this.pidReport.timezone != null) {
            param['timezone'] = this.pidReport.timezone;
          }

          if (this.pidReport.sourceIds != null && this.pidReport.sourceIds.length > 0) {
            param['sourceIds'] = this.pidReport.sourceIds + '';
          }
          if (this.pidReport.affiliateIds != null) {
            param['affiliateIds'] = this.pidReport.affiliateIds + '';
          }
          if (this.pidReport.offerIds != null) {
            param['offerIds'] = this.pidReport.offerIds;
          }
          if (this.pidReport.platform != null && this.pidReport.platform != '') {
            param['platform'] = this.pidReport.platform;
          }
          param = filterObject(param);

          let strParam = qs.stringify(param);

          window.open(`${api.DOWNLOAD_PID_REPORT}?${strParam}`, '_blank');
        });
      },
      handleSizeChange(size) {
        this.pageParam.pageSize = size;
        this.handleCurrentChange(1);
      },
      handleCurrentChange(currentPage) {
        this.pageParam.page = currentPage;
        this.pidReportApi();
      },
      affiliatetailClick(row) {
        const { href } = this.$router.resolve({
          path: '/affiliate/detail',
          query: {
            affiliateId: row.affiliateId,
          },
        });
        window.open(href, '_blank');
      },
      sourcedetailClick(row) {
        const { href } = this.$router.resolve({
          path: '/source/detail',
          query: {
            sourceId: row.sourceId,
          },
        });
        window.open(href, '_blank');
      },
      offerdetailClick(row) {
        const { href } = this.$router.resolve({
          path: '/offer/detail',
          query: {
            offerId: row.offerId,
          },
        });
        window.open(href, '_blank');
      },
      filterNull(val) {
        return val == null ? 0 : val;
      },
      showReportComparingCrItem(conversion, clickCount) {
        console.log(conversion, clickCount);
        const cur = new Number((conversion / (0 == clickCount ? 1 : clickCount)) * 100).toFixed(6);
        return cur + '%';
      },
      showReportComparingRpcItem(affConversion, clickClount) {
        const cur = new Number(affConversion / (0 == clickClount ? 1 : clickClount)).toFixed(5);
        let result = cur;
        return result;
      },
      handleSelectionChange(val) {
        this.blackInfo.blackList = val.map((item) => ({
          offerId: item.offerId || '',
          affiliateId: item.affiliateId,
        }));
      },
    },
  };
</script>
<style lang="scss" scoped>
  .black-dialog {
    width: 400px;
    p {
      line-height: 30px;
    }
    input {
      width: 300px;
    }
  }
</style>
<style lang="scss"></style>
